import Cookies from 'js-cookie';

// "ResizeObserver loop completed with undelivered notifications." というエラーが起きているが
// どこで ResizeObserver が使われているかわからないので、使われたときに Bugsnag に情報を送る
// ローカルで試した限りでは使われているところはなさそうに見えるので、
// もしかしたら Chrome 拡張など環境依存のコードかもしれない。

const COOKIE_KEY = 'RONotify';
const EXPIRATION_DAYS = 7;

let oldResizeObserver: any = null;
export default function hookResizeObserver() {
  oldResizeObserver = window.ResizeObserver;
  const wrapper: any = function(_callback: ResizeObserverCallback) {
    try {
      // 頻繁にBugsnag通知されすぎても困るので数日に1回しか通知しないようにする
      if (Cookies.get(COOKIE_KEY) == null) {
        // 呼ばれた箇所を調べるために Error を通じてスタックトレースを取得する
        const e = new Error('ResizeObserver created');
        window.bugsnagClient?.notify(e);

        Cookies.set(COOKIE_KEY, 't',  { expires: EXPIRATION_DAYS });
      }
    } catch(_error) {
      // 調査コードのせいで本来のコードが動かないと困るので、調査コード中のあらゆるエラーを無視して
      // 本来のコードを実行する
    }
    return new oldResizeObserver(...arguments);
  }
  window.ResizeObserver = wrapper;
}
