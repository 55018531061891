import Bugsnag from '@bugsnag/js';
import type { Event as BugsnagEvent } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BugsnagPluginCustomError } from '@sonicgarden/bugsnag-plugin-custom-error'
import hookResizeObserver from '@/lib/resize_observer_hook';

const customErrorPlugin = new BugsnagPluginCustomError({
  ResizeObserverUndeliveredNotification: [
    'ResizeObserver loop completed with undelivered notifications',
  ],
});

export default function initBugsnag(environment: string) {
  const bugsnagClient = Bugsnag.start({
    // APIキーは公開されるJSに埋め込まれて秘密にできないものなので、ソースコードに直書きしてしまっても問題ない
    apiKey: '859b37bfc59dd76e97448ce64cc653d7',
    plugins: [new BugsnagPluginReact(), customErrorPlugin],
    releaseStage: environment,
    enabledReleaseStages: ['staging', 'production', 'staging-de', 'production-de'],
    onError: function (event: BugsnagEvent) {
      if (window.APP.staffId) {
        event.setUser(window.APP.staffId.toString());
      }
    }
  });

  // 別の script タグでアプリケーションの主要JSコードをロードするが、そちらでも Bugsnag を使うためにグローバル変数にする
  window.bugsnagClient = bugsnagClient;

  // ResizeObserver がどこで使われているか調べるための調査コード
  hookResizeObserver();
}
